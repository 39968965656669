<template>
  <div class="data-box">
    <van-nav-bar
        title="物品发放"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">负责人</span>
          <span class="span-name align-right">{{ materialInfo.name ? materialInfo.name : "无" }}</span>
          <a :href="'tel:' + materialInfo.phone" class="iconfont icon-dianhua1" v-show="materialInfo.phone"></a>
        </div>
      </div>
      <div class="body-div" v-show="materialList.length > 0">
        <div class="body-div-title align-center">
          物品清单
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt>物品名称</dt>
              <dt>限领数量</dt>
              <dt>已领数量</dt>
              <dt>回收</dt>
            </tr>
            <tr v-for="(item,index) in materialList" :key="index">
              <dd style="color: #3476FF;" @click="toMaterialDetails(item)">{{ item.str }}</dd>
              <dd>{{ item.num2 }}</dd>
              <dd>{{ item.num4 }}</dd>
              <dd>
                <i :class="[item.num3 ? 'icon-tongguo' : 'icon-weitongguo', 'iconfont']" :style="{color: item.num3 ? '#5ABB2B' : '#FF4A41'}"></i>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  showActivityMaterial
} from "@/api/index";

export default {
  name: "orgCheck_material",
  data() {
    return {
      materialInfo: {
        name: "",
        phone: ""
      },
      materialList: []
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getSelectOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 4){
          that.materialInfo.name = item.chargeName;
          that.materialInfo.phone = item.chargePhone;
        }
      })
      that.getMaterialInfo();
    },
    getMaterialInfo(){
      let that = this;
      let params = {
        ayId : that.$store.getters.getSelectOPDetails.id
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialList = res.data.materialList;
          that.materialList.forEach(item => {
            item.num4 = Math.abs(item.num4);
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toMaterialDetails(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_material_details',
        query: {
          id: item.id,
          ayId: that.$store.getters.getSelectOPDetails.id
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>